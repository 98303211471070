import * as React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useFormikContext } from 'formik'
import { SelectMultipleField } from '@toasttab/buffet-pui-forms'
import { CustomerFein, RtsRequestBody } from './models/rtsModel'
import { SelectOption } from '@toasttab/buffet-pui-select'

export const CustomersSelectMultiple = () => {
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [options, setOptions] = React.useState<SelectOption[]>([])
  const { values, setFieldValue } = useFormikContext<RtsRequestBody>()

  const { data } = useQuery({
    queryKey: ['active-feins'],
    initialData: [],
    queryFn: async () => {
      const response = await fetch('/rts-generation-job/active-feins')

      if (!response.ok) {
        throw new Error('Error fetching active FEINs: ' + response.status)
      }

      return response.json()
    }
  })

  const createSelectOptions = (customerFein: CustomerFein) => {
    const { legalName, companyCode, tin, feinUuid, startDate } = customerFein

    return {
      label: `${companyCode} - ${legalName} - ${tin} - ${startDate}`,
      value: feinUuid
    }
  }

  const filterData = (searchTerm: string) => {
    if (!data || data.length === 0) {
      return []
    }

    const filteredOptions = data
      .filter((customerFein: CustomerFein) => {
        const { legalName, companyCode, tin, feinUuid } = customerFein

        if (values.feinList?.includes(feinUuid)) {
          return true
        }

        if (!searchTerm) {
          return true
        }

        return (
          companyCode.toLowerCase().includes(searchTerm) ||
          legalName.toLowerCase().includes(searchTerm) ||
          tin.includes(searchTerm)
        )
      })
      .slice(0, 100)
      .map(createSelectOptions)

    // always put the selected options at the top of the list
    const selectedOptions = values.feinList?.map((feinUuid) => {
      const customerFein = data.find(
        (item: CustomerFein) => item.feinUuid === feinUuid
      )

      return createSelectOptions(customerFein)
    }) as SelectOption[]

    return [...new Set(selectedOptions.concat(filteredOptions))]
  }

  React.useEffect(() => {
    setOptions(filterData(searchTerm))
  }, [searchTerm, data])

  return (
    <SelectMultipleField
      label='Customers'
      name='feinList'
      enableMultiLineChips
      enableSearch
      searchAnyPartOfString={true}
      maxChips={30}
      value={values.feinList ?? []}
      testId='select-multiple'
      itemToValue={(item) => item?.value}
      onSearchChange={(string) => {
        setSearchTerm(string.toLowerCase())
      }}
      onChange={(selectedValues) => {
        setFieldValue('feinList', selectedValues)
      }}
      options={options}
    />
  )
}
