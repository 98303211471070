import * as React from 'react'
import { RouteObject } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import {
  DividingLine,
  Panel,
  SettingGroupHeader
} from '@toasttab/buffet-pui-config-templates'
import { useUser } from '@toasttab/ec-session'
import { RtsForm } from './RtsForm'
import { RtsList } from './RtsList'
import { useQuery } from '@tanstack/react-query'
import { RtsFile } from './models/rtsModel'

export const RTSRoute: RouteObject = {
  path: 'rts',
  element: <Rts />,
  children: []
}

function Rts() {
  const [refetchInterval, setRefetchInterval] = React.useState(0)
  const { isUserPayrollOps } = useUser()

  const getRefetchInterval = (data: RtsFile[]): number => {
    // @ts-ignore
    return data.some((rtsFile) => rtsFile.status === 'PENDING')
      ? 2 * 60 * 1000 // 2 minutes
      : 0
  }

  const { data, refetch } = useQuery({
    queryKey: ['rts-files'],
    initialData: [],
    refetchIntervalInBackground: true,
    refetchInterval: refetchInterval,
    queryFn: async () => {
      const response = await fetch('/rts-generation-job/rts-files')

      if (!response.ok) {
        throw new Error('Error fetching RTS files: ' + response.status)
      }

      return response.json()
    }
  })

  React.useEffect(() => {
    if (data) {
      setRefetchInterval(getRefetchInterval(data))
    }
  }, [data])

  if (!isUserPayrollOps) {
    return (
      <div className='type-default m-6'>
        Hmmmm.... You're not suppose to be here.
      </div>
    )
  }

  return (
    <Panel className='type-default'>
      <div className='flex justify-between items-center'>
        <SettingGroupHeader title='Quarterly RTS file' />
        <Button testId='generate' type='submit' form='rts-form'>
          Generate
        </Button>
      </div>
      <DividingLine />
      <RtsForm refetch={refetch} />
      <DividingLine />
      <RtsList rtsFiles={data} refetch={refetch} />
    </Panel>
  )
}
