import * as React from 'react'
import { useCallback } from 'react'
import { CelebrationIllustration } from '@toasttab/buffet-pui-illustrations'
import { Layout } from '../layout'
import { useUserMigrationState } from '../UserMigrationContextProvider'
import { Migrated } from '../state'
import { useTranslation } from '@local/translations'
import { Logo } from '../logo'
import {
  ContentFooter,
  ContinueButton,
  NavigationContainer,
  PageMain
} from '@toasttab/buffet-pui-wizard-templates'
import { logout } from '../redirects'

function insertBetween<T>(element: T, array: T[]) {
  return array.flatMap((x) => [element, x]).slice(1)
}

const CompanyCodes = (props: { companyCodes: string[] }) => {
  const { companyCodes } = props

  const items = companyCodes.map((companyCode, index) => (
    <span
      key={`companyCode-${index}`}
      data-testid={`companyCode-${index}`}
      className='font-bold'
    >
      {companyCode}
    </span>
  ))

  if (items.length > 1) {
    const lastIndex = items.length - 1
    items[lastIndex] = <> and {items[lastIndex]}</>
  }
  if (items.length > 2) {
    return insertBetween(<>, </>, items)
  } else {
    return items
  }
}

export const Complete = () => {
  const state = useUserMigrationState<Migrated>()
  const { t } = useTranslation()

  const handleContinue = useCallback(() => {
    logout(state.returnUrl)
  }, [state.returnUrl])

  return (
    <Layout>
      <PageMain>
        <div className='p-10 flex flex-col items-center'>
          <Logo />
          <h1 className='type-headline-2'>{t('complete.youAreAllSetUp')}</h1>
          <p className='mt-8 text-center max-w-sm'>
            <span data-testid='email' className='font-bold'>
              {state.email}
            </span>{' '}
            {t('complete.isReadyToBeUsedToLogin') + ' '}
            <CompanyCodes companyCodes={state.companyCodes} />
          </p>

          <CelebrationIllustration />
        </div>

        <ContentFooter>
          <NavigationContainer>
            <ContinueButton onClick={handleContinue} testId='continue'>
              {t('continue')}
            </ContinueButton>
          </NavigationContainer>
        </ContentFooter>
      </PageMain>
    </Layout>
  )
}
