import { RtsRequestBody, RunType, Status } from '../models/rtsModel'

export const buildRtsRequestBody = (values: RtsRequestBody): RtsRequestBody => {
  return {
    quarter: values.quarter,
    year: values.year,
    method: values.method,
    feinList: values.method === 'enterprise' ? undefined : values.feinList,
    includeMissingSsns: values.includeMissingSsns
  }
}

export const getStatus = (status: string): Status | string => {
  return Object.keys(Status).includes(status as Status)
    ? Status[status as keyof typeof Status]
    : 'Unknown'
}

export const getRunType = (runType: string): RunType | string => {
  return Object.keys(RunType).includes(runType as RunType)
    ? RunType[runType as keyof typeof RunType]
    : 'Unknown'
}
