/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> =
  { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** An `ISO-8601` compliant Full Date Scalar - e.g. 2022-06-01 */
  Date: { input: Date; output: Date }
  /** An `ISO-8601` compliant DateTime Scalar, e.g. 2022-06-01T10:15:00.000 */
  DateTime: { input: Date; output: Date }
  /**  A signed decimal number, which supports arbitrary precision and is serialized as a string. Example values: "29.99", "29.999".  */
  Decimal: { input: number; output: number }
  Long: { input: number; output: number }
}

export type AbsentPayChangeLogTokenError = {
  __typename?: 'AbsentPayChangeLogTokenError'
  message?: Maybe<Scalars['String']['output']>
}

export type AccessibleCustomer = {
  __typename?: 'AccessibleCustomer'
  companyCode: Scalars['String']['output']
  name: Scalars['String']['output']
  shardName: Scalars['String']['output']
}

export type AccessibleCustomersInput = {
  cursor?: InputMaybe<Scalars['ID']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type AccessibleCustomersResult = {
  __typename?: 'AccessibleCustomersResult'
  customers: Array<AccessibleCustomer>
  nextCursor?: Maybe<Scalars['ID']['output']>
}

export type ActiveEmploymentStatus = EmploymentStatus & {
  __typename?: 'ActiveEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type Actor = {
  __typename?: 'Actor'
  /** The ID of the actor */
  id: Scalars['ID']['output']
  /** The actor's primary job name */
  jobName?: Maybe<Scalars['String']['output']>
  /** The name of the actor */
  name?: Maybe<PersonName>
}

export type ActorPermissionsForEmployee = {
  __typename?: 'ActorPermissionsForEmployee'
  employeeId: Scalars['ID']['output']
  permissions: Array<HumanResourcesPermission>
}

export type AdditionalDocumentInput = {
  fileName: Scalars['String']['input']
  filePath: Scalars['String']['input']
}

export type AdditionalEarningResponse =
  | EarningListEmptyError
  | EmployeeNotFoundError
  | JobAssignmentNotFoundError
  | MissingPayGroupError
  | NextPayPeriodNotFoundError
  | NextPaycheckDateNotFoundError
  | Paycheck

export type AllEmploymentStatuses =
  | ActiveEmploymentStatus
  | ApplicantEmploymentStatus
  | DeletedEmploymentStatus
  | DemoEmploymentStatus
  | ImplementationEmploymentStatus
  | LeaveOfAbsenceEmploymentStatus
  | NeverEmployedEmploymentStatus
  | PendingEmploymentStatus
  | RehiredEmploymentStatus
  | SeasonalEmploymentStatus
  | TerminatedEmploymentStatus

export type AmountWithPayInterval = {
  __typename?: 'AmountWithPayInterval'
  amount: Money
  interval: PayInterval
}

export type ApplicantEmploymentStatus = EmploymentStatus & {
  __typename?: 'ApplicantEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type Assignee = 'CUSTOMER' | 'ONBOARDING'

export type AvailabilityRequest = {
  email?: InputMaybe<Scalars['String']['input']>
  employeeId?: InputMaybe<Scalars['ID']['input']>
  employeeNumber?: InputMaybe<Scalars['Int']['input']>
  posAccessCode?: InputMaybe<Scalars['Int']['input']>
  ssn?: InputMaybe<Scalars['String']['input']>
}

export type AvailabilityResponse = {
  __typename?: 'AvailabilityResponse'
  email?: Maybe<Scalars['Boolean']['output']>
  emailTakenBy?: Maybe<EmploymentStatusType>
  employeeNumber?: Maybe<Scalars['Boolean']['output']>
  posAccessCode?: Maybe<Scalars['Boolean']['output']>
  ssn?: Maybe<Scalars['Boolean']['output']>
}

export type Benefit = {
  __typename?: 'Benefit'
  key: Scalars['String']['output']
  label: Scalars['String']['output']
  options: Array<BenefitOption>
}

export type BenefitOption = {
  __typename?: 'BenefitOption'
  label: Scalars['String']['output']
  value: Scalars['ID']['output']
}

export type Benefits = {
  __typename?: 'Benefits'
  acaPlan?: Maybe<Scalars['String']['output']>
  benefitPlan?: Maybe<Scalars['String']['output']>
  tafwPolicy?: Maybe<Scalars['String']['output']>
  washingtonLaI?: Maybe<Scalars['String']['output']>
  workersComp?: Maybe<Scalars['String']['output']>
}

export type BenefitsInput = {
  acaPlan?: InputMaybe<Scalars['String']['input']>
  benefitPlan?: InputMaybe<Scalars['String']['input']>
  tafwPolicy?: InputMaybe<Scalars['String']['input']>
  washingtonLaI?: InputMaybe<Scalars['String']['input']>
  workersComp?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsThirdPartyDataSharingConsent = {
  __typename?: 'BenefitsThirdPartyDataSharingConsent'
  version: Scalars['Float']['output']
}

export type BulkNotifyResponse = {
  __typename?: 'BulkNotifyResponse'
  employee: Scalars['ID']['output']
  status: DirectDepositNotificationStatus
}

export type CannotChangeLinkedUsername = {
  __typename?: 'CannotChangeLinkedUsername'
  userId: Scalars['ID']['output']
}

export type CannotChangeUnlinkedUsername = {
  __typename?: 'CannotChangeUnlinkedUsername'
  userId: Scalars['ID']['output']
}

export type ChangeLinkedUsernameResult =
  | CannotChangeUnlinkedUsername
  | InvalidUsername
  | PayrollUser
  | ToastVersionIgnored
  | UserNotFound
  | UsernameExists

export type ChangePayInput = {
  effectiveDate: Scalars['Date']['input']
  rate: MoneyInput
}

export type ChangeSalaryAllocationInput = {
  /**  Date the new rate should be applied. If more than 1 SalaryAllocation is being updated, effectiveDate must be today's date  */
  effectiveDate: Scalars['Date']['input']
  /** Id of the salary allocation */
  id: Scalars['ID']['input']
  /** New annual rate for this salary allocation */
  newRate: MoneyInput
}

export type ChangeSalaryResponse =
  | EmployeeNotFoundError
  | EmptySalaryAllocationsError
  | InvalidEffectiveDateError
  | Salary
  | SalaryAllocationNotFoundError
  | SalaryNotFoundError

export type ChangeUnlinkedUsernameResult =
  | CannotChangeLinkedUsername
  | InvalidUsername
  | PayrollUser
  | UserNotFound
  | UsernameExists

export type ChecklistState = {
  __typename?: 'ChecklistState'
  customerUuid: Scalars['ID']['output']
  items: Array<Item>
}

export type CompleteDirectDepositEmployee = {
  __typename?: 'CompleteDirectDepositEmployee'
  allocations: Array<DirectDepositAllocation>
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: PersonName
}

export type CompleteDirectDepositsResponse = {
  __typename?: 'CompleteDirectDepositsResponse'
  employees: Array<CompleteDirectDepositEmployee>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  totalCount: Scalars['Int']['output']
}

export type ConfirmVerificationEmailError =
  | ConfirmationExpired
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type ConfirmVerificationEmailInput = {
  confirmationKey: Scalars['String']['input']
}

export type ConfirmVerificationEmailResult =
  | ConfirmationExpired
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailConfirmed
  | VerificationEmailNotSent

export type ConfirmationExpired = {
  __typename?: 'ConfirmationExpired'
  message?: Maybe<Scalars['String']['output']>
}

export type ContactTelephoneNumber = {
  __typename?: 'ContactTelephoneNumber'
  isMobile: Scalars['Boolean']['output']
  telephoneNumber: Scalars['String']['output']
}

export type Country = {
  __typename?: 'Country'
  code: Scalars['String']['output']
  displayName: Scalars['String']['output']
  isoCode: Scalars['String']['output']
}

export type CreateJobTagAssignmentInput = {
  createdByUserGuid: Scalars['ID']['input']
  jobTagId: Scalars['ID']['input']
  managementSetId: Scalars['ID']['input']
  restaurantId: Scalars['ID']['input']
  restaurantJobId: Scalars['ID']['input']
  selectedJobTagValue: Scalars['String']['input']
}

export type CreateJobTagInput = {
  createdByUserGuid: Scalars['ID']['input']
  name: Scalars['String']['input']
  values: Array<Scalars['String']['input']>
}

/** An `ISO-4217` compliant Currency. */
export type Currency =
  /** Canadian Dollars (CAD). */
  | 'CAD'
  /** Euro (EUR). */
  | 'EUR'
  /** United Kingdom Pounds (GBP). */
  | 'GBP'
  /** United States Dollars (USD). */
  | 'USD'

export type CustomDocumentFieldInput = {
  fieldId: Scalars['Int']['input']
  id: Scalars['ID']['input']
  value: Scalars['String']['input']
}

export type CustomDocumentFields = {
  __typename?: 'CustomDocumentFields'
  documentId: Scalars['ID']['output']
  fieldId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type CustomDocumentFieldsV2 = {
  __typename?: 'CustomDocumentFieldsV2'
  documentIds: Array<Scalars['ID']['output']>
  fieldId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Customer = {
  __typename?: 'Customer'
  /** application features as defined in ToastWeb, related to entitlements */
  enabledFeatures: Array<Scalars['String']['output']>
  feins: Array<PayrollFein>
  id: Scalars['ID']['output']
  locations: Array<Location>
  /** @deprecated Field no longer supported */
  uuid: Scalars['ID']['output']
}

export type CustomerConfiguration = {
  __typename?: 'CustomerConfiguration'
  customerPositions: Array<CustomerPosition>
  defaultEarningsIds?: Maybe<DefaultEarningsId>
  /** customerUuid */
  id: Scalars['ID']['output']
  locations: Array<PayrollLocation>
  payGroups: Array<PayGroupWithFrequency>
  payGroupsV2: Array<PayGroup>
  payrollSecurityRoles: Array<PayrollSecurityRole>
  workTaxLocations: Array<PayrollWorkTaxLocation>
}

export type CustomerInput = {
  locations: Array<LocationInput>
  uuid: Scalars['ID']['input']
}

export type CustomerPosition = {
  __typename?: 'CustomerPosition'
  checkCode: PayrollCheckCode
  fein: PayrollFein
  id: Scalars['ID']['output']
  isExempt: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  payGroup: PayGroup
  salaryType: Scalars['String']['output']
}

export type CustomerSetting = {
  __typename?: 'CustomerSetting'
  id: Scalars['Int']['output']
  value: Scalars['String']['output']
}

export type DataExceptionError = {
  __typename?: 'DataExceptionError'
  message?: Maybe<Scalars['String']['output']>
}

export type DateRange = {
  __typename?: 'DateRange'
  endDate?: Maybe<Scalars['Date']['output']>
  startDate: Scalars['Date']['output']
}

export type DefaultEarningsId = {
  __typename?: 'DefaultEarningsId'
  contractorHourly?: Maybe<Scalars['ID']['output']>
  contractorSalary?: Maybe<Scalars['ID']['output']>
  hourly: Scalars['ID']['output']
  salary: Scalars['ID']['output']
}

export type DeletedEmploymentStatus = EmploymentStatus & {
  __typename?: 'DeletedEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type DemoEmploymentStatus = EmploymentStatus & {
  __typename?: 'DemoEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type DepositAccountType =
  | 'CHECKING'
  | 'MONEYMARKET'
  | 'PAYCARD'
  | 'SAVING'

export type DirectDepositAllocation = {
  __typename?: 'DirectDepositAllocation'
  amount: Scalars['Float']['output']
  depositAccountType: DepositAccountType
  sequence: Scalars['Int']['output']
  splitDepositType: SplitDepositType
}

export type DirectDepositBulkEmailResponse = {
  __typename?: 'DirectDepositBulkEmailResponse'
  id: Scalars['ID']['output']
  status: DirectDepositNotificationStatus
}

export type DirectDepositNotification = {
  __typename?: 'DirectDepositNotification'
  email?: Maybe<Scalars['String']['output']>
  lastContactedUtc?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<DirectDepositNotificationStatus>
}

export type DirectDepositNotificationStatus =
  | 'FAILURE_TO_SEND'
  | 'SUCCESSFULLY_SENT'

export type DirectDepositPaginationInput = {
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type DisabilityStatus = 'DOES_NOT_WISH_TO_ANSWER' | 'NO' | 'YES'

export type DriversLicense = {
  __typename?: 'DriversLicense'
  expirationDate: Scalars['Date']['output']
  number: Scalars['String']['output']
  state: StateInUnitedStates
}

export type DuplicateRequest = {
  __typename?: 'DuplicateRequest'
  message?: Maybe<Scalars['String']['output']>
}

export type EarningListEmptyError = {
  __typename?: 'EarningListEmptyError'
  message?: Maybe<Scalars['String']['output']>
}

export type EeoClassification = {
  __typename?: 'EeoClassification'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type EeoClassificationInput = {
  id: Scalars['Int']['input']
  name: Scalars['String']['input']
}

export type EmailAlreadyVerified = {
  __typename?: 'EmailAlreadyVerified'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailHasBeenUpdated = {
  __typename?: 'EmailHasBeenUpdated'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailInvalidError = {
  __typename?: 'EmailInvalidError'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailNotFound = {
  __typename?: 'EmailNotFound'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailSendingError = {
  __typename?: 'EmailSendingError'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailVerification = {
  __typename?: 'EmailVerification'
  isVerified?: Maybe<Scalars['Boolean']['output']>
  toastIdentityGuid: Scalars['ID']['output']
  verifiedTime?: Maybe<Scalars['DateTime']['output']>
}

export type EmailVerificationError =
  | ConfirmationExpired
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailNotFound
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type EmailVerificationFailed = {
  __typename?: 'EmailVerificationFailed'
  message?: Maybe<Scalars['String']['output']>
}

export type EmailVerificationMutation = {
  __typename?: 'EmailVerificationMutation'
  confirmVerificationEmail: ConfirmVerificationEmailResult
  sendVerificationEmail: SendVerificationEmailResult
}

export type EmailVerificationMutationConfirmVerificationEmailArgs = {
  input?: InputMaybe<ConfirmVerificationEmailInput>
}

export type EmailVerificationMutationSendVerificationEmailArgs = {
  input?: InputMaybe<SendVerificationEmailInput>
}

export type EmailVerificationQuery = {
  __typename?: 'EmailVerificationQuery'
  verification?: Maybe<EmailVerificationResult>
}

export type EmailVerificationResult =
  | ConfirmationExpired
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailNotFound
  | EmailVerification
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailNotSent

export type EmailVerificationSourceType = 'PAYROLL' | 'SCHEDULING'

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  isPrimary?: Maybe<Scalars['Boolean']['output']>
  lastName: Scalars['String']['output']
  relation: Scalars['String']['output']
  telephoneNumber?: Maybe<Scalars['String']['output']>
}

export type Employee = {
  __typename?: 'Employee'
  benefits?: Maybe<Benefits>
  companyCode?: Maybe<Scalars['String']['output']>
  contact: EmployeeContact
  demographics: EmployeeDemographics
  emergencyContacts: Array<EmergencyContact>
  employment?: Maybe<Employment>
  hasSsn?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  identification: EmployeeIdentification
  isEmployeeZero: Scalars['Boolean']['output']
  jobAssignmentPayChangeErrors: Array<JobAssignment>
  jobAssignments: Array<JobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  payHistory: EmployeePayHistory
  payrollSecurityRole?: Maybe<PayrollSecurityRole>
  salary?: Maybe<Salary>
  salaryChangeErrors?: Maybe<Salary>
  supervisors: Array<EmployeeSupervisor>
  user?: Maybe<User>
  /** @deprecated Please use user */
  userUuid: Scalars['ID']['output']
}

export type EmployeeAtLocation = {
  __typename?: 'EmployeeAtLocation'
  jobs: Array<EmployeeJob>
  mappingStatus: MappingCondition
  payrollLocationId?: Maybe<LevelId>
  payrollLocationName?: Maybe<Scalars['String']['output']>
  posEmail?: Maybe<Scalars['String']['output']>
  posLocationGuid?: Maybe<Scalars['ID']['output']>
  posMostRecentClockInDate?: Maybe<Scalars['Date']['output']>
  posRestaurantUserGuid?: Maybe<Scalars['ID']['output']>
  posStatus?: Maybe<Scalars['String']['output']>
  posUserName?: Maybe<PersonName>
}

export type EmployeeContact = {
  __typename?: 'EmployeeContact'
  homeAddress?: Maybe<PostalAddress>
  mailingAddress?: Maybe<PostalAddress>
  telephoneNumber?: Maybe<ContactTelephoneNumber>
}

export type EmployeeDemographics = {
  __typename?: 'EmployeeDemographics'
  dateOfBirth?: Maybe<Scalars['Date']['output']>
  disability?: Maybe<DisabilityStatus>
  ethnicity?: Maybe<Ethnicity>
  gender?: Maybe<Gender>
  maritalStatus?: Maybe<MaritalStatus>
  veteranStatus: Array<VeteranStatus>
}

export type EmployeeDocumentUploadRequest = {
  fileName: Scalars['String']['input']
}

export type EmployeeIdentification = {
  __typename?: 'EmployeeIdentification'
  driversLicense?: Maybe<DriversLicense>
  hasSocialSecurityNumber: Scalars['Boolean']['output']
}

export type EmployeeJob = {
  __typename?: 'EmployeeJob'
  mappingStatus: MappingCondition
  payrollId?: Maybe<LevelId>
  payrollRateOfPay?: Maybe<Money>
  payrollTitle?: Maybe<Scalars['String']['output']>
  posGuid?: Maybe<Scalars['ID']['output']>
  posRateOfPay?: Maybe<Money>
  posTitle?: Maybe<Scalars['String']['output']>
}

export type EmployeeMappingStatus = {
  __typename?: 'EmployeeMappingStatus'
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  issues: Array<MappingIssue>
  locations: Array<EmployeeAtLocation>
  name: PersonName
  status: EmploymentStatusType
}

export type EmployeeNewHireDocument = {
  __typename?: 'EmployeeNewHireDocument'
  fileName: Scalars['String']['output']
  filePath: Scalars['String']['output']
  uploadUrl: Scalars['String']['output']
}

export type EmployeeNotFoundError = {
  __typename?: 'EmployeeNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type EmployeePay = {
  __typename?: 'EmployeePay'
  mostRecentPaycheck?: Maybe<Scalars['String']['output']>
}

export type EmployeePayHistory = {
  __typename?: 'EmployeePayHistory'
  mostRecentPaycheck?: Maybe<Scalars['String']['output']>
}

export type EmployeePaymentMethodEmailResponse = {
  __typename?: 'EmployeePaymentMethodEmailResponse'
  messageId?: Maybe<Scalars['String']['output']>
}

export type EmployeeStatusTypeCount = {
  __typename?: 'EmployeeStatusTypeCount'
  active?: Maybe<Scalars['Int']['output']>
  applicant?: Maybe<Scalars['Int']['output']>
  deleted?: Maybe<Scalars['Int']['output']>
  demo?: Maybe<Scalars['Int']['output']>
  dormant?: Maybe<Scalars['Int']['output']>
  implementation?: Maybe<Scalars['Int']['output']>
  leaveOfAbsence?: Maybe<Scalars['Int']['output']>
  neverEmployed?: Maybe<Scalars['Int']['output']>
  notSpecified?: Maybe<Scalars['Int']['output']>
  pendingActive?: Maybe<Scalars['Int']['output']>
  terminated?: Maybe<Scalars['Int']['output']>
}

export type EmployeeSupervisor = {
  __typename?: 'EmployeeSupervisor'
  id: Scalars['ID']['output']
  job?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type Employment = {
  __typename?: 'Employment'
  eeoClassification: EeoClassification
  employeeNumber?: Maybe<Scalars['String']['output']>
  employmentStatus: AllEmploymentStatuses
  employmentType?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  overtimeEligible?: Maybe<Scalars['Boolean']['output']>
  totalLengthOfService?: Maybe<DateRange>
}

export type EmploymentDetails = {
  __typename?: 'EmploymentDetails'
  employeeNumber: Scalars['Int']['output']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']['output']
}

export type EmploymentDetailsInput = {
  defaultEarningsCode: Scalars['ID']['input']
  eeoClassification?: InputMaybe<EeoClassificationInput>
  employeeNumber: Scalars['Int']['input']
  employmentTaxType: EmploymentTaxType
  hireDate: Scalars['Date']['input']
}

export type EmploymentId = {
  __typename?: 'EmploymentId'
  customerId: Scalars['ID']['output']
  employeeId: Scalars['ID']['output']
}

export type EmploymentStatus = {
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type EmploymentStatusType =
  | 'ACTIVE'
  | 'APPLICANT'
  | 'DELETED'
  | 'DEMO'
  | 'IMPLEMENTATION'
  | 'LEAVE_OF_ABSENCE'
  | 'NEVER_EMPLOYED'
  | 'NOT_SPECIFIED'
  | 'PENDING_ACTIVE'
  | 'SEASONAL'
  | 'TERMINATED'

export type EmploymentTaxType = 'K1' | 'UNKNOWN' | 'W2' | '_1099'

export type EmptySalaryAllocationsError = {
  __typename?: 'EmptySalaryAllocationsError'
  message?: Maybe<Scalars['String']['output']>
}

export type Ethnicity =
  | 'AMERICAN_INDIAN_OR_ALASKA_NATIVE'
  | 'ASIAN'
  | 'BLACK_OR_AFRICAN_AMERICAN'
  | 'DO_NOT_WISH_TO_IDENTIFY'
  | 'HAWAIIAN_OR_OTHER_PACIFIC_ISLAND'
  | 'HISPANIC_OR_LATINO'
  | 'TWO_OR_MORE_RACES'
  | 'WHITE'

export type FindUserEventsByUserIdInput = {
  fromVersion?: InputMaybe<Scalars['Long']['input']>
  maxNumResults?: InputMaybe<Scalars['Int']['input']>
  userId: Scalars['ID']['input']
}

export type Gender = 'DOES_NOT_WISH_TO_IDENTIFY' | 'FEMALE' | 'MALE'

export type GenericResponse = {
  __typename?: 'GenericResponse'
  message: Scalars['String']['output']
}

export type HourlyEarningInput = {
  earningCodeId: Scalars['ID']['input']
  hours: Scalars['Decimal']['input']
  rate: MoneyInput
}

export type HourlyRetroPay = {
  __typename?: 'HourlyRetroPay'
  hoursWorked: Scalars['Decimal']['output']
  payChangeDifference: AmountWithPayInterval
  retroPay: Money
}

export type HourlyRetroPayInput = {
  employeeId: Scalars['ID']['input']
  endDate: Scalars['Date']['input']
  jobAssignmentId: Scalars['ID']['input']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']['input']
}

export type HourlyRetroPayResponse =
  | HourlyRetroPay
  | InvalidStartDateError
  | JobAssignmentNotFoundError
  | TimesheetRetrievalError

export type HumanResourcesPermission =
  | 'CHANGE_UNVERIFIED_USER_EMAIL'
  | 'CHANGE_VERIFIED_USER_EMAIL'
  | 'CUSTOMER_CONFIGURATION_EDIT'
  | 'CUSTOMER_CONFIGURATION_VIEW'
  | 'CUSTOMER_JOB_MAPPING_ADMIN_EDIT'
  | 'CUSTOMER_JOB_MAPPING_ADMIN_VIEW'
  | 'EDIT_MY_PROFILE'
  | 'EMPLOYEE_ACCESS_ALL'
  | 'EMPLOYEE_ACCESS_ALL_FOR_CUSTOMER'
  | 'EMPLOYEE_EDIT'
  | 'EMPLOYEE_MAPPING_EDIT'
  | 'EMPLOYEE_VIEW'
  | 'EMPLOYMENT_EDIT'
  | 'EMPLOYMENT_STATUS_EDIT'
  | 'EMPLOYMENT_STATUS_VIEW'
  | 'EMPLOYMENT_VIEW'
  | 'JOB_ASSIGNMENT_EDIT'
  | 'JOB_ASSIGNMENT_VIEW'
  | 'MIGRATE_USERS'
  | 'PAY_CARD_AUDIT_VIEW'
  | 'PAY_CHANGE_HISTORY_VIEW'
  | 'PAY_EDIT'
  | 'PAY_HISTORY_VIEW'
  | 'SSN_EDIT'
  | 'SSN_EXISTS_VIEW'
  | 'VIEW_MY_PROFILE'
  | 'VIEW_PERMISSIONS'

export type ImplementationEmploymentStatus = EmploymentStatus & {
  __typename?: 'ImplementationEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type IncompleteDirectDepositEmployee = {
  __typename?: 'IncompleteDirectDepositEmployee'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: PersonName
  notification?: Maybe<DirectDepositNotification>
}

export type IncompleteDirectDepositInput = {
  direction: SortDirection
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
  sort: IncompleteDirectDepositSortColumn
}

export type IncompleteDirectDepositSortColumn = 'LAST_CONTACTED' | 'NAME'

export type IncompleteDirectDepositsResponse = {
  __typename?: 'IncompleteDirectDepositsResponse'
  employees: Array<IncompleteDirectDepositEmployee>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  totalCount: Scalars['Int']['output']
}

export type InputCannotBeEmpty = {
  __typename?: 'InputCannotBeEmpty'
  message?: Maybe<Scalars['String']['output']>
}

export type IntercomClientType = 'ANDROID' | 'IOS' | 'WEB'

export type IntercomSessionDataResponse = {
  __typename?: 'IntercomSessionDataResponse'
  intercomUserHash?: Maybe<Scalars['String']['output']>
  user?: Maybe<User>
}

export type InvalidEffectiveDateError = {
  __typename?: 'InvalidEffectiveDateError'
  message?: Maybe<Scalars['String']['output']>
}

export type InvalidPayChangeLogTokenError = {
  __typename?: 'InvalidPayChangeLogTokenError'
  message?: Maybe<Scalars['String']['output']>
}

export type InvalidStartDateError = {
  __typename?: 'InvalidStartDateError'
  message?: Maybe<Scalars['String']['output']>
}

export type InvalidUsername = {
  __typename?: 'InvalidUsername'
  username: Scalars['String']['output']
}

export type IssueSeverity = 'ERROR' | 'INFO' | 'WARN'

export type IssueType =
  | 'ACTIVE_EE_MAPPED_WITH_ARCHIVED_POS'
  | 'JOB_GUID_IS_MALFORMED'
  | 'JOB_IS_MISSING_IN_POS'
  | 'JOB_NOT_MAPPED'
  | 'LOCATION_GUID_IS_MALFORMED'
  | 'LOCATION_IS_MISSING_IN_POS'
  | 'LOCATION_NOT_MAPPED'
  | 'MAPPED_INTO_DIFFERENT_TOAST_IDENTITY'
  | 'PAYROLL_JOBS_MISMATCH_POS_JOBS'
  | 'POS_EE_MAPPING_IGNORED'
  | 'POS_EE_MAPPING_UNKNOWN_LOCATION_GUID'
  | 'RU_COUNTERPART_IS_MISSING_IN_POS'
  | 'UNMAPPED_PAYROLL_SPECIALTY'
  | 'UNMAPPED_POS_RU'

export type Item = {
  __typename?: 'Item'
  assignedTo: Assignee
  key: Scalars['ID']['output']
  status: TaskStatus
  subItems: Array<Item>
  title: Scalars['String']['output']
}

export type Job = {
  __typename?: 'Job'
  exportId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type JobAssignment = {
  __typename?: 'JobAssignment'
  activeHourlyRateOfPay?: Maybe<Money>
  employmentTaxType?: Maybe<EmploymentTaxType>
  id: Scalars['ID']['output']
  isPrimary: Scalars['Boolean']['output']
  jobId: Scalars['ID']['output']
  locationId: Scalars['ID']['output']
  locationName: Scalars['String']['output']
  name: Scalars['String']['output']
  payGroupId?: Maybe<Scalars['ID']['output']>
  pendingPayChange?: Maybe<PendingPayChange>
}

export type JobAssignmentNotFoundError = {
  __typename?: 'JobAssignmentNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type JobInput = {
  restaurantJobGuid?: InputMaybe<Scalars['ID']['input']>
  uuid: Scalars['ID']['input']
}

export type JobMapping = {
  __typename?: 'JobMapping'
  payrollJob: PayrollJob
  restaurantJob: RestaurantJob
}

export type JobTag = {
  __typename?: 'JobTag'
  createdByUserGuid: Scalars['ID']['output']
  createdDate: Scalars['Date']['output']
  deletedDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  modifiedByUserGuid?: Maybe<Scalars['ID']['output']>
  modifiedDate?: Maybe<Scalars['Date']['output']>
  name: Scalars['String']['output']
  values: Array<Scalars['String']['output']>
}

export type JobTagAssignment = {
  __typename?: 'JobTagAssignment'
  createdByUserGuid: Scalars['ID']['output']
  createdDate: Scalars['Date']['output']
  deletedDate?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  jobTagId: Scalars['ID']['output']
  jobTagName: Scalars['String']['output']
  managementSetId: Scalars['ID']['output']
  modifiedByUserGuid?: Maybe<Scalars['ID']['output']>
  modifiedDate?: Maybe<Scalars['Date']['output']>
  restaurantId: Scalars['ID']['output']
  restaurantJobId: Scalars['ID']['output']
  selectedJobTagValue: Scalars['String']['output']
}

export type KeyDoesNotMatch = {
  __typename?: 'KeyDoesNotMatch'
  message?: Maybe<Scalars['String']['output']>
}

export type LeaveOfAbsenceEmploymentStatus = EmploymentStatus & {
  __typename?: 'LeaveOfAbsenceEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
  leaveType?: Maybe<Scalars['String']['output']>
  scheduledLeave?: Maybe<DateRange>
}

export type LevelId = {
  __typename?: 'LevelId'
  databaseId: Scalars['Int']['output']
  sequenceId: Scalars['Int']['output']
}

export type LevelIdInput = {
  databaseId: Scalars['Int']['input']
  sequenceId: Scalars['Int']['input']
}

export type LinkIfMappedResult =
  | LinkingNotAvailable
  | MappedUserMismatchedUsername
  | PayrollUser
  | UserAlreadyLinked
  | UserNotMapped

export type LinkUserResult = PayrollUser | UserNotFound

export type LinkingNotAvailable = {
  __typename?: 'LinkingNotAvailable'
  toastIdentityGuid: Scalars['ID']['output']
}

export type LocalizableMessage = {
  __typename?: 'LocalizableMessage'
  key: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type Location = {
  __typename?: 'Location'
  name?: Maybe<Scalars['String']['output']>
  restaurant?: Maybe<Restaurant>
  status: LocationMappingStatus
  uuid: Scalars['ID']['output']
}

export type LocationInput = {
  restaurantGuid?: InputMaybe<Scalars['ID']['input']>
  uuid: Scalars['ID']['input']
}

export type LocationMappingStatus = {
  __typename?: 'LocationMappingStatus'
  code: LocationMappingStatusCode
  message: Scalars['String']['output']
}

export type LocationMappingStatusCode =
  | 'NoStatus'
  | 'RestaurantGuidConnected'
  | 'RestaurantGuidDoubleMapped'
  | 'RestaurantGuidEmpty'
  | 'RestaurantGuidInvalid'

export type LumpSumEarningInput = {
  amount: MoneyInput
  earningCodeId: Scalars['ID']['input']
}

export type MappedUserMismatchedUsername = {
  __typename?: 'MappedUserMismatchedUsername'
  username: Scalars['String']['output']
}

export type MappingAssignment = {
  locationGuid: Scalars['ID']['input']
  restaurantUserGuid: Scalars['ID']['input']
}

export type MappingCondition =
  | 'MAPPED_INVALID_GUID'
  | 'MAPPED_NO_POS_COUNTERPART'
  | 'MAPPED_USER_MAPPED'
  | 'MAPPED_USER_MAPPING_IGNORED'
  | 'MAPPED_USER_NOT_MAPPED'
  | 'NOT_MAPPED'
  | 'POS_ONLY'

export type MappingIssue = {
  __typename?: 'MappingIssue'
  severity: IssueSeverity
  type: IssueType
}

export type MaritalStatus =
  | 'DIVORCED'
  | 'HEAD_OF_HOUSEHOLD'
  | 'MARRIED'
  | 'SINGLE'
  | 'WIDOW'

export type MissingPayGroupError = {
  __typename?: 'MissingPayGroupError'
  message?: Maybe<Scalars['String']['output']>
}

/** A monetary value with currency. */
export type Money = {
  __typename?: 'Money'
  /** The amount of money */
  amount: Scalars['Decimal']['output']
  /** The currency the money is paid in */
  currency: Currency
}

export type MoneyInput = {
  amount: Scalars['Decimal']['input']
  currency: Currency
}

export type Mutation = {
  __typename?: 'Mutation'
  applyMapping: Scalars['String']['output']
  /** @deprecated Please use directDepositBulkEmail */
  bulkNotify: Array<BulkNotifyResponse>
  changeHelloWorldResponse?: Maybe<Response>
  changeHourlyPay?: Maybe<JobAssignment>
  /**  Change salary pay: provide a list of the updated SalaryAllocations and their new annual pay rate  */
  changeSalaryAllocations: ChangeSalaryResponse
  contactOnboarding?: Maybe<GenericResponse>
  createJobTag?: Maybe<JobTag>
  createJobTagAssignment?: Maybe<JobTagAssignment>
  /** @deprecated please use updatePendingPayChangeStatuses */
  deletePendingPayChange?: Maybe<Scalars['ID']['output']>
  directDepositBulkEmail: Array<DirectDepositBulkEmailResponse>
  emailVerification?: Maybe<EmailVerificationMutation>
  ignorePosUserInPayroll?: Maybe<Scalars['Boolean']['output']>
  payrollUser?: Maybe<PayrollUserMutations>
  rehireEmployeeNoChanges: Scalars['ID']['output']
  rehireEmployeeWithChanges: Scalars['ID']['output']
  resyncTasks: ChecklistState
  saveAdditionalEarnings?: Maybe<AdditionalEarningResponse>
  saveNewEmployee?: Maybe<SaveNewEmployeeResponse>
  sendJobMappingRequest?: Maybe<Scalars['String']['output']>
  sendUpdateEmployeePaymentMethodEmail?: Maybe<UpdateEmployeePaymentMethodEmailResponse>
  setHasPreviousPayrollProvider: GenericResponse
  updateBenefitsConsent: BenefitsThirdPartyDataSharingConsent
  updateCustomer: Customer
  updateJobMappings: Array<PayrollJob>
  updatePayCardCustomerSettings: UpdatePayCardCustomerSettingsResponse
  updatePendingPayChangeStatuses?: Maybe<UpdatePendingPayChangeStatusResponse>
  updateTask: ChecklistState
  userMigration?: Maybe<UserMigrationMutation>
}

export type MutationApplyMappingArgs = {
  locationId: Scalars['ID']['input']
  payrollEmployeeId: Scalars['ID']['input']
  restaurantUserId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationBulkNotifyArgs = {
  employees: Array<Scalars['ID']['input']>
}

export type MutationChangeHelloWorldResponseArgs = {
  response: Scalars['String']['input']
}

export type MutationChangeHourlyPayArgs = {
  changePayInput: ChangePayInput
  employeeId: Scalars['ID']['input']
  jobAssignmentId: Scalars['ID']['input']
}

export type MutationChangeSalaryAllocationsArgs = {
  changeSalaryAllocations: Array<ChangeSalaryAllocationInput>
  employeeId: Scalars['ID']['input']
}

export type MutationContactOnboardingArgs = {
  emailContent?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateJobTagArgs = {
  jobTagInput: CreateJobTagInput
}

export type MutationCreateJobTagAssignmentArgs = {
  jobTagAssignmentInput: CreateJobTagAssignmentInput
}

export type MutationDeletePendingPayChangeArgs = {
  employeeId: Scalars['ID']['input']
  pendingPayChangeId: Scalars['ID']['input']
}

export type MutationDirectDepositBulkEmailArgs = {
  employees: Array<Scalars['ID']['input']>
}

export type MutationIgnorePosUserInPayrollArgs = {
  userGuid: Scalars['ID']['input']
}

export type MutationRehireEmployeeNoChangesArgs = {
  request: RehireEmployeeNoChangesInput
}

export type MutationRehireEmployeeWithChangesArgs = {
  employeeId: Scalars['ID']['input']
  rehireDate: Scalars['Date']['input']
  request: NewEmployeeInput
}

export type MutationSaveAdditionalEarningsArgs = {
  employeeId: Scalars['ID']['input']
  hourlyEarnings?: Array<HourlyEarningInput>
  jobAssignmentId: Scalars['ID']['input']
  lumpSumEarnings?: Array<LumpSumEarningInput>
}

export type MutationSaveNewEmployeeArgs = {
  request: NewEmployeeInput
}

export type MutationSendJobMappingRequestArgs = {
  attachedNote?: InputMaybe<Scalars['String']['input']>
  employeeUuid: Scalars['ID']['input']
  payrollJobId?: InputMaybe<LevelIdInput>
  payrollLocationId?: InputMaybe<LevelIdInput>
}

export type MutationSendUpdateEmployeePaymentMethodEmailArgs = {
  employeeId: Scalars['ID']['input']
}

export type MutationSetHasPreviousPayrollProviderArgs = {
  hasPreviousPayrollProvider: Scalars['Boolean']['input']
}

export type MutationUpdateBenefitsConsentArgs = {
  version: Scalars['Float']['input']
}

export type MutationUpdateCustomerArgs = {
  customerInput: CustomerInput
}

export type MutationUpdateJobMappingsArgs = {
  jobs: Array<JobInput>
  locationId: Scalars['ID']['input']
}

export type MutationUpdatePayCardCustomerSettingsArgs = {
  input: UpdatePayCardCustomerSettingsInput
}

export type MutationUpdatePendingPayChangeStatusesArgs = {
  employeeId: Scalars['ID']['input']
  updatePendingPayChangeStatusInput?: Array<PendingPayChangeStatusInput>
}

export type MutationUpdateTaskArgs = {
  status: TaskStatus
  taskKey: Scalars['String']['input']
}

export type NavigableTaxAccount = {
  __typename?: 'NavigableTaxAccount'
  companyCode: Scalars['String']['output']
  legalName: Scalars['String']['output']
  shardName: Scalars['String']['output']
  tin: Scalars['String']['output']
}

export type NavigableTaxAccountResult = {
  __typename?: 'NavigableTaxAccountResult'
  nextPageNumber?: Maybe<Scalars['Int']['output']>
  taxAccounts: Array<NavigableTaxAccount>
}

export type NavigableTaxAccountsInput = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type Navigation = {
  __typename?: 'Navigation'
  children: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']['output']
  labelNew: LocalizableMessage
  matchUrls: Array<Scalars['String']['output']>
  name: Scalars['String']['output']
  pageType?: Maybe<PageType>
  path: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type NavigationError = {
  __typename?: 'NavigationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type NavigationErrors = {
  __typename?: 'NavigationErrors'
  errors: Array<NavigationError>
  path: Scalars['String']['output']
}

export type NavigationInput = {
  params?: InputMaybe<Array<NavigationParam>>
  path?: InputMaybe<Scalars['String']['input']>
}

export type NavigationParam = {
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type NavigationResult = {
  __typename?: 'NavigationResult'
  errors: Array<NavigationErrors>
  result?: Maybe<Navigation>
}

export type NeverEmployedEmploymentStatus = EmploymentStatus & {
  __typename?: 'NeverEmployedEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type NewEmployeeInput = {
  additionalDocuments: Array<AdditionalDocumentInput>
  benefits: BenefitsInput
  customDocumentFields: Array<CustomDocumentFieldInput>
  documents: Array<Scalars['ID']['input']>
  employment: EmploymentDetailsInput
  jobsAndPay: NewJobsAndPayInput
  overtimeEligible: Scalars['Boolean']['input']
  person: PersonInput
  securityRoleId: Scalars['ID']['input']
}

export type NewHireDocument = {
  __typename?: 'NewHireDocument'
  assignedByPosition: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type NewJobAssignment = {
  __typename?: 'NewJobAssignment'
  hourlyRate?: Maybe<Money>
  jobId: Scalars['ID']['output']
  locationId: Scalars['ID']['output']
}

export type NewJobAssignmentInput = {
  hourlyRate?: InputMaybe<MoneyInput>
  jobId: Scalars['ID']['input']
  locationId: Scalars['ID']['input']
}

export type NewJobsAndPay = {
  __typename?: 'NewJobsAndPay'
  annualSalary?: Maybe<Money>
  jobs: Array<NewJobAssignment>
  payGroupAssignments: Array<PayGroupAssignment>
  salaryPerPayPeriod?: Maybe<Money>
  salaryRateOfPay?: Maybe<Money>
}

export type NewJobsAndPayInput = {
  annualSalary?: InputMaybe<MoneyInput>
  jobs: Array<NewJobAssignmentInput>
  mappings: Array<MappingAssignment>
  payGroupAssignments: Array<PayGroupAssignmentInput>
  salaryPerPayPeriod?: InputMaybe<MoneyInput>
  salaryRateOfPay?: InputMaybe<MoneyInput>
}

export type NextPayPeriodNotFoundError = {
  __typename?: 'NextPayPeriodNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type NextPaycheckDateNotFoundError = {
  __typename?: 'NextPaycheckDateNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type NotAToastUser = {
  __typename?: 'NotAToastUser'
  message?: Maybe<Scalars['String']['output']>
}

export type OnboardingCheckListPermission =
  | 'EDIT_PAYROLL_ONBOARDING'
  | 'VIEW_PAYROLL_ONBOARDING'

export type PageType = 'HEADER_ONLY' | 'LINK_ONLY'

export type PayCardAdminSettings = {
  __typename?: 'PayCardAdminSettings'
  payCardAdminPayoutsLocked?: Maybe<Scalars['Boolean']['output']>
}

export type PayCardBooleanAccessFactor = 'FALSE' | 'TRUE' | 'UNKNOWN'

export type PayCardBusinessAccess = {
  __typename?: 'PayCardBusinessAccess'
  businessId?: Maybe<Scalars['ID']['output']>
  existsInPayroll: Scalars['Boolean']['output']
  fein: Scalars['String']['output']
  isContractComplete: Scalars['Boolean']['output']
  restaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccess = {
  __typename?: 'PayCardCustomerAccess'
  businesses: Array<PayCardBusinessAccess>
  customerUuid: Scalars['ID']['output']
  detachedRestaurants: Array<PayCardRestaurantAccess>
}

export type PayCardCustomerAccessInput = {
  skipCache: Scalars['Boolean']['input']
}

export type PayCardCustomerSettings = {
  __typename?: 'PayCardCustomerSettings'
  payCardAdminSettings?: Maybe<PayCardAdminSettings>
  payCardPayoutConfiguration?: Maybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled?: Maybe<Scalars['Boolean']['output']>
  payCardTipsConfiguration?: Maybe<PayCardTipsConfiguration>
}

/** Represents one atomic change to customer settings. */
export type PayCardCustomerSettingsAuditEntry = {
  __typename?: 'PayCardCustomerSettingsAuditEntry'
  change: PayCardCustomerSettingsAuditHistoryChangeContent
  /** The timestamp in which the change was made */
  datetime: Scalars['DateTime']['output']
  /** User that made the change */
  user: PayCardCustomerSettingsAuditUser
}

/**  Content that represents what the change was that occurred. Note we only return a message key here not the actual string. The front end will need to resolve the actual localized string.  */
export type PayCardCustomerSettingsAuditHistoryChangeContent = {
  __typename?: 'PayCardCustomerSettingsAuditHistoryChangeContent'
  messageKey: Scalars['String']['output']
}

export type PayCardCustomerSettingsAuditUser = {
  __typename?: 'PayCardCustomerSettingsAuditUser'
  displayName: Scalars['String']['output']
}

export type PayCardEmployeeAccess = {
  __typename?: 'PayCardEmployeeAccess'
  accessFactors: PayCardEmployeeAccessFactors
  accessLevel: PayCardEmployeeAccessLevel
  employeeUuid: Scalars['ID']['output']
}

export type PayCardEmployeeAccessFactors = {
  __typename?: 'PayCardEmployeeAccessFactors'
  cardActivated?: Maybe<PayCardBooleanAccessFactor>
  highestRestaurantAccessLevel?: Maybe<PayCardRestaurantAccessLevel>
  isEmployed?: Maybe<Scalars['Boolean']['output']>
  isLocEnabled?: Maybe<PayCardBooleanAccessFactor>
  isWorkTaxLocationStateEligible?: Maybe<Scalars['Boolean']['output']>
}

export type PayCardEmployeeAccessInput = {
  employeeUuid: Scalars['ID']['input']
  skipCache: Scalars['Boolean']['input']
}

export type PayCardEmployeeAccessLevel =
  | 'NONE'
  | 'PAYCARD'
  | 'PAYOUTS'
  | 'SIGNUP'

export type PayCardEmployerAccessFactors =
  | 'ENROLLMENT_FEATURE_FLAG'
  | 'FEIN_IN_PAYROLL'
  | 'HAS_COMPLETED_CONTRACT'
  | 'PAYOUT_ENTITLEMENT'
  | 'PAY_CARD_ENTITLEMENT'
  | 'SDP_FEATURE_FLAG'

export type PayCardEmployerAccessLevel =
  | 'EMPLOYEE_PAYOUTS'
  | 'EMPLOYEE_SIGNUP'
  | 'NONE'
  | 'ONBOARDING'

export type PayCardPayoutConfiguration =
  | 'TIPS_ONLY'
  | 'WAGES_AND_TIPS'
  | 'WAGES_ONLY'

export type PayCardRestaurantAccess = {
  __typename?: 'PayCardRestaurantAccess'
  accessFactors: Array<PayCardEmployerAccessFactors>
  accessLevel: PayCardEmployerAccessLevel
  restaurantGuid: Scalars['ID']['output']
}

export type PayCardRestaurantAccessLevel = 'NONE' | 'PAYOUTS' | 'SIGNUP'

export type PayCardTipsConfiguration = 'TIPS_ALL' | 'TIPS_WITH_WITHHOLDING'

export type PayChangeEvent = {
  __typename?: 'PayChangeEvent'
  /**  Information about the actor that initiated the pay change event. If null, the actor is unknown.  */
  actor?: Maybe<Actor>
  /**  The date and time the pay change event was initiated. If null, the date is unknown.  */
  createdAt?: Maybe<Scalars['DateTime']['output']>
  /**  The current pay rate for the job to which the pay change event is referencing.  */
  currentRate: AmountWithPayInterval
  /** The date and time the pay change did / will take effect. */
  effectiveAt?: Maybe<Scalars['DateTime']['output']>
  /** The ID of the pay change event */
  id: Scalars['ID']['output']
  /** The job name to which the pay change event is referencing */
  jobName: Scalars['String']['output']
  /**  The location name for the job to which the pay change event is referencing  */
  locationName: Scalars['String']['output']
  /**  The previous pay rate for the job to which the pay change event is referencing. If null, this pay change event is not tied to a previous pay, i.e. for new jobs  */
  previousRate?: Maybe<AmountWithPayInterval>
  /** The current status of the pay change even. */
  status: PayChangeEventStatus
}

export type PayChangeEventStatus =
  | 'CANCELED'
  | 'ERROR'
  | 'ERROR_ACKNOWLEDGED'
  | 'NEW_JOB'
  | 'SCHEDULED'
  | 'UPDATED'

export type PayChangeLog = {
  __typename?: 'PayChangeLog'
  /**  Page token used to retrieve the current page of pay change events. Passing this value through as the pageToken in subsequent requests will result in the same pay change events being retrieved.  */
  currentPageToken?: Maybe<Scalars['String']['output']>
  /**  The employee's pay change events, default sort is via createdDate newest -> oldest  */
  data?: Maybe<Array<PayChangeEvent>>
  /**  Page token used to retrieve the next page of pay change events. When this field is null that indicates that [currentPageToken] was the last page in the result set.  */
  nextPageToken?: Maybe<Scalars['String']['output']>
  /**  Page token used to retrieve the previous page of pay change events. When the field is null that indicates that indicates that the [currentPageToken] was the first page in the result set.  */
  previousPageToken?: Maybe<Scalars['String']['output']>
  /** The total count of this employee's pay change events */
  totalResultCount: Scalars['Int']['output']
}

export type PayChangeLogResponse =
  | AbsentPayChangeLogTokenError
  | InvalidPayChangeLogTokenError
  | PayChangeLog

export type PayChangeLogSortField = 'CREATE_DATE'

export type PayChangeLogSortInput = {
  /** Whether to sort in ascending or descending order for this field */
  direction: SortDirection
  /** The field by which to sort the pay change log events */
  sortField: PayChangeLogSortField
}

export type PayFrequency =
  | 'ANNUAL'
  | 'BIWEEKLY'
  | 'MONTHLY'
  | 'NONE'
  | 'QUARTERLY'
  | 'SEMIANNUAL'
  | 'SEMIMONTHLY'
  | 'WEEKLY'

export type PayGroup = {
  __typename?: 'PayGroup'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayGroupAssignment = {
  __typename?: 'PayGroupAssignment'
  checkCodeId: Scalars['ID']['output']
  locationIds: Array<Scalars['ID']['output']>
  payGroupId: Scalars['ID']['output']
  positionId: Scalars['ID']['output']
  workTaxLocationId: Scalars['ID']['output']
}

export type PayGroupAssignmentInput = {
  checkCodeId: Scalars['ID']['input']
  locationIds: Array<Scalars['ID']['input']>
  payGroupId: Scalars['ID']['input']
  positionId?: InputMaybe<Scalars['ID']['input']>
  workTaxLocationId: Scalars['ID']['input']
}

export type PayGroupWithFrequency = {
  __typename?: 'PayGroupWithFrequency'
  checkCodeId: Scalars['ID']['output']
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  payGroupId: Scalars['ID']['output']
  periodsPerYear: Scalars['Int']['output']
}

export type PayInterval = 'ANNUAL' | 'HOUR'

export type Paycheck = {
  __typename?: 'Paycheck'
  date?: Maybe<Scalars['Date']['output']>
}

export type PayrollCheckCode = {
  __typename?: 'PayrollCheckCode'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  periodsPerYear: Scalars['Int']['output']
}

export type PayrollCustomerUser = {
  __typename?: 'PayrollCustomerUser'
  companyCode: Scalars['String']['output']
  customerId: Scalars['ID']['output']
  customerName: Scalars['String']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  shardName: Scalars['String']['output']
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  username: Scalars['String']['output']
}

export type PayrollCustomerUserSource = 'DB' | 'DEX'

export type PayrollCustomerUsersInput = {
  source: PayrollCustomerUserSource
  toastIdentityGuid?: InputMaybe<Scalars['ID']['input']>
  userId?: InputMaybe<Scalars['ID']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type PayrollEmployeeMapping = {
  __typename?: 'PayrollEmployeeMapping'
  name: PersonName
  payrollEmployeeNumber?: Maybe<Scalars['String']['output']>
}

export type PayrollFein = {
  __typename?: 'PayrollFein'
  benefits: Array<ThirdPartyBenefit>
  fein?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollJob = {
  __typename?: 'PayrollJob'
  name: Scalars['String']['output']
  uuid: Scalars['ID']['output']
}

export type PayrollLocation = {
  __typename?: 'PayrollLocation'
  exportId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  jobs: Array<Job>
  name: Scalars['String']['output']
}

export type PayrollSecurityRole = {
  __typename?: 'PayrollSecurityRole'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollUser = {
  __typename?: 'PayrollUser'
  customerId: Scalars['ID']['output']
  email?: Maybe<Scalars['String']['output']>
  employeeId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  username: Scalars['String']['output']
}

export type PayrollUserMutations = {
  __typename?: 'PayrollUserMutations'
  changeLinkedUsername: Array<ChangeLinkedUsernameResult>
  changeUnlinkedUsername: ChangeUnlinkedUsernameResult
  link: LinkUserResult
  linkIfMapped: Array<LinkIfMappedResult>
  unlink: UnlinkUserResult
}

export type PayrollUserMutationsChangeLinkedUsernameArgs = {
  toastIdentityGuid: Scalars['ID']['input']
  username: Scalars['String']['input']
}

export type PayrollUserMutationsChangeUnlinkedUsernameArgs = {
  customerId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
  username: Scalars['String']['input']
}

export type PayrollUserMutationsLinkArgs = {
  customerId: Scalars['ID']['input']
  toastIdentityGuid: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type PayrollUserMutationsUnlinkArgs = {
  customerId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type PayrollWorkTaxLocation = {
  __typename?: 'PayrollWorkTaxLocation'
  address: PayrollWorkTaxLocationAddress
  fein: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type PayrollWorkTaxLocationAddress = {
  __typename?: 'PayrollWorkTaxLocationAddress'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  gnisCode?: Maybe<Scalars['String']['output']>
  line1?: Maybe<Scalars['String']['output']>
  line2?: Maybe<Scalars['String']['output']>
  line3?: Maybe<Scalars['String']['output']>
  state?: Maybe<Scalars['String']['output']>
  zipCode?: Maybe<Scalars['String']['output']>
}

export type PendingEmploymentStatus = EmploymentStatus & {
  __typename?: 'PendingEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type PendingPayChange = {
  __typename?: 'PendingPayChange'
  effectiveDate: Scalars['Date']['output']
  id: Scalars['ID']['output']
  rate: Money
  status: PendingPayChangeStatus
}

export type PendingPayChangeNotFoundError = {
  __typename?: 'PendingPayChangeNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type PendingPayChangeNotFoundErrors = {
  __typename?: 'PendingPayChangeNotFoundErrors'
  pendingPayChangeNotFoundErrors: Array<PendingPayChangeNotFoundError>
}

export type PendingPayChangeStatus =
  | 'APPLIED'
  | 'CANCELED'
  | 'ERROR'
  | 'ERROR_ACKNOWLEDGED'
  | 'PENDING'

export type PendingPayChangeStatusInput = {
  pendingPayChangeId: Scalars['ID']['input']
  pendingPayChangeStatus: PendingPayChangeStatus
}

export type PendingPayChangeStatusResponse = {
  __typename?: 'PendingPayChangeStatusResponse'
  pendingPayChanges: Array<PendingPayChange>
}

export type Person = {
  __typename?: 'Person'
  chosenName?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
  posAccessCode: Scalars['Int']['output']
  ssn?: Maybe<Scalars['String']['output']>
}

export type PersonInput = {
  chosenName?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  posAccessCode?: InputMaybe<Scalars['Int']['input']>
  ssn?: InputMaybe<Scalars['String']['input']>
}

export type PersonName = {
  __typename?: 'PersonName'
  chosen?: Maybe<Scalars['String']['output']>
  first?: Maybe<Scalars['String']['output']>
  formatted?: Maybe<Scalars['String']['output']>
  last?: Maybe<Scalars['String']['output']>
  middle?: Maybe<Scalars['String']['output']>
}

export type PosAvailabilityRequest = {
  exportIds: Array<Scalars['ID']['input']>
  posAccessCode: Scalars['Int']['input']
}

export type PosEmployee = {
  __typename?: 'PosEmployee'
  archived: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  externalEmployeeId?: Maybe<Scalars['String']['output']>
  mappedToEmployee?: Maybe<PayrollEmployeeMapping>
  mostRecentClockInDate?: Maybe<Scalars['Date']['output']>
  restaurantUserGuid: Scalars['ID']['output']
  userName: PersonName
}

export type PosRawEmployee = {
  __typename?: 'PosRawEmployee'
  archived: Scalars['Boolean']['output']
  disabled: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  locationGuid: Scalars['ID']['output']
  payrollLocationId: Scalars['Int']['output']
  restaurantUserGuid: Scalars['ID']['output']
  userGuid: Scalars['ID']['output']
  userName: PersonName
}

export type PosUser = {
  __typename?: 'PosUser'
  email: Scalars['String']['output']
  restaurantUsers: Array<RestaurantUser>
  userGuid: Scalars['ID']['output']
  userName: PersonName
}

export type PostalAddress = {
  __typename?: 'PostalAddress'
  administrativeArea: Scalars['String']['output']
  country: Country
  locality: Scalars['String']['output']
  postalCode: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
  streetAddress2?: Maybe<Scalars['String']['output']>
}

export type Query = {
  __typename?: 'Query'
  accessibleCustomers: AccessibleCustomersResult
  actorPermissionsForEmployees: Array<ActorPermissionsForEmployee>
  checkAvailability: AvailabilityResponse
  checkPosAvailability?: Maybe<Scalars['Boolean']['output']>
  completeDirectDeposits: CompleteDirectDepositsResponse
  customer: Customer
  customerBenefits?: Maybe<Array<Maybe<Benefit>>>
  customerConfiguration?: Maybe<CustomerConfiguration>
  directDepositEmailTemplate?: Maybe<Scalars['String']['output']>
  documentCenter: UserDocuments
  emailVerification?: Maybe<EmailVerificationQuery>
  /** @deprecated Please use findEmployeeById */
  employee: Employee
  employeeDocumentUploadUrl: EmployeeNewHireDocument
  employeeStatusTypeCount: EmployeeStatusTypeCount
  findCustomerSettings: Array<CustomerSetting>
  findEmployeeById?: Maybe<Employee>
  findEmployeeForRestaurantByToastIdentityGuid?: Maybe<Employee>
  /** @deprecated Please use findEmployeeById */
  findEmployeePay?: Maybe<EmployeePay>
  findJobMappingByJobId?: Maybe<JobMapping>
  findJobMappingsByLocationId: Array<JobMapping>
  findPayrollJobsByLocationId: Array<PayrollJob>
  findRestaurantJobsByRestaurantId: Array<RestaurantJob>
  findUserByEmployeeId?: Maybe<User>
  findUserById?: Maybe<User>
  findUserEventsByUserId: Array<UserEvent>
  /** @deprecated Please use findUserById */
  findUsers?: Maybe<Users>
  getEmployeeMappingStatus: EmployeeMappingStatus
  getPosMappingOptions: Array<PosEmployee>
  getPosUser: Array<PosRawEmployee>
  getPosUserV2?: Maybe<PosUser>
  getUnmappedPosUsers: Array<PosRawEmployee>
  helloFromBenefits: Scalars['String']['output']
  helloWorld: Response
  incompleteDirectDeposits: IncompleteDirectDepositsResponse
  intercomSessionData?: Maybe<IntercomSessionDataResponse>
  jobAssignmentsById: Array<JobAssignment>
  navigableTaxAccounts: NavigableTaxAccountResult
  navigation: NavigationResult
  newHireRequiredDocumentsForAssignedFields: Array<NewHireDocument>
  nextAvailableEmployeeNumber?: Maybe<Scalars['Int']['output']>
  numRunPayrollsInThePastYear?: Maybe<Scalars['Int']['output']>
  payCardCustomerAccess: PayCardCustomerAccess
  payCardCustomerSettings: PayCardCustomerSettings
  payCardCustomerSettingsAuditHistory: Array<PayCardCustomerSettingsAuditEntry>
  payCardCustomerSettingsLastEvent?: Maybe<PayCardCustomerSettingsAuditEntry>
  payCardEmployeeAccess: PayCardEmployeeAccess
  /** Pay change log for the specified employee. */
  payChangeLog?: Maybe<PayChangeLogResponse>
  payrollCustomerUsers: Array<PayrollCustomerUser>
  payrollOnboardingPermissions: Array<OnboardingCheckListPermission>
  requiredDocumentationCustomFields: Array<CustomDocumentFields>
  requiredDocumentationCustomFieldsV2: Array<CustomDocumentFieldsV2>
  restaurant?: Maybe<Restaurant>
  retroPayForHourlyPayChange?: Maybe<HourlyRetroPayResponse>
  retroPayForSalaryChange?: Maybe<SalaryRetroPayResponse>
  sections: SectionResult
  userMigration?: Maybe<UserMigrationQuery>
}

export type QueryAccessibleCustomersArgs = {
  input?: InputMaybe<AccessibleCustomersInput>
}

export type QueryActorPermissionsForEmployeesArgs = {
  employeeIds: Array<Scalars['ID']['input']>
}

export type QueryCheckAvailabilityArgs = {
  request: AvailabilityRequest
}

export type QueryCheckPosAvailabilityArgs = {
  request: PosAvailabilityRequest
}

export type QueryCompleteDirectDepositsArgs = {
  completeDirectDepositInput: DirectDepositPaginationInput
}

export type QueryDocumentCenterArgs = {
  documentType: UserDocumentType
}

export type QueryEmployeeArgs = {
  employeeUuid: Scalars['ID']['input']
}

export type QueryEmployeeDocumentUploadUrlArgs = {
  request: EmployeeDocumentUploadRequest
}

export type QueryFindCustomerSettingsArgs = {
  settings: Array<Scalars['Int']['input']>
}

export type QueryFindEmployeeByIdArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryFindEmployeeForRestaurantByToastIdentityGuidArgs = {
  toastIdentityGuid: Scalars['ID']['input']
  toastRestaurantGuid: Scalars['ID']['input']
}

export type QueryFindEmployeePayArgs = {
  employeeUuid: Scalars['ID']['input']
}

export type QueryFindJobMappingByJobIdArgs = {
  jobId: Scalars['ID']['input']
}

export type QueryFindJobMappingsByLocationIdArgs = {
  locationId: Scalars['ID']['input']
}

export type QueryFindPayrollJobsByLocationIdArgs = {
  locationId: Scalars['ID']['input']
}

export type QueryFindRestaurantJobsByRestaurantIdArgs = {
  restaurantId: Scalars['ID']['input']
}

export type QueryFindUserByEmployeeIdArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryFindUserByIdArgs = {
  userId: Scalars['ID']['input']
}

export type QueryFindUserEventsByUserIdArgs = {
  input: FindUserEventsByUserIdInput
}

export type QueryFindUsersArgs = {
  companyCode: Scalars['ID']['input']
  employeeUuid: Scalars['ID']['input']
}

export type QueryGetEmployeeMappingStatusArgs = {
  employeeUuid: Scalars['ID']['input']
}

export type QueryGetPosMappingOptionsArgs = {
  employeeUuid: Scalars['ID']['input']
  locationUuid: Scalars['ID']['input']
}

export type QueryGetPosUserArgs = {
  userGuid: Scalars['ID']['input']
}

export type QueryGetPosUserV2Args = {
  userGuid: Scalars['ID']['input']
}

export type QueryIncompleteDirectDepositsArgs = {
  incompleteDirectDepositInput: IncompleteDirectDepositInput
}

export type QueryIntercomSessionDataArgs = {
  intercomClientType: IntercomClientType
}

export type QueryJobAssignmentsByIdArgs = {
  employeeId: Scalars['ID']['input']
  jobAssignmentIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryNavigableTaxAccountsArgs = {
  input?: InputMaybe<NavigableTaxAccountsInput>
}

export type QueryNavigationArgs = {
  input?: InputMaybe<NavigationInput>
}

export type QueryNewHireRequiredDocumentsForAssignedFieldsArgs = {
  request: RequiredDocumentsRequest
}

export type QueryPayCardCustomerAccessArgs = {
  input: PayCardCustomerAccessInput
}

export type QueryPayCardEmployeeAccessArgs = {
  input: PayCardEmployeeAccessInput
}

export type QueryPayChangeLogArgs = {
  employeeId: Scalars['ID']['input']
  pageNumber?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  pageToken?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<Array<PayChangeLogSortInput>>
}

export type QueryPayrollCustomerUsersArgs = {
  input?: InputMaybe<PayrollCustomerUsersInput>
}

export type QueryRestaurantArgs = {
  restaurantGuid: Scalars['ID']['input']
}

export type QueryRetroPayForHourlyPayChangeArgs = {
  hourlyRetroPayInput: HourlyRetroPayInput
}

export type QueryRetroPayForSalaryChangeArgs = {
  salaryRetroPayInput: SalaryRetroPayInput
}

export type QuerySectionsArgs = {
  input?: InputMaybe<SectionsInput>
}

export type RehireEmployeeNoChangesInput = {
  employeeId: Scalars['ID']['input']
  rehireDate: Scalars['Date']['input']
}

export type RehiredEmploymentStatus = EmploymentStatus & {
  __typename?: 'RehiredEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  dismissalPeriod?: Maybe<DateRange>
  hireDate?: Maybe<Scalars['String']['output']>
  rehireDate?: Maybe<Scalars['String']['output']>
}

export type RequiredDocumentsRequest = {
  hireDate: Scalars['Date']['input']
  securityRoleId: Scalars['ID']['input']
  workTaxLocationIds: Array<Scalars['ID']['input']>
}

export type Response = {
  __typename?: 'Response'
  response?: Maybe<Scalars['String']['output']>
}

export type Restaurant = {
  __typename?: 'Restaurant'
  guid: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
}

export type RestaurantJob = {
  __typename?: 'RestaurantJob'
  guid: Scalars['ID']['output']
  name: Scalars['String']['output']
  status: RestaurantJobStatusCode
}

export type RestaurantJobStatusCode =
  | 'Available'
  /** Restaurant job does not exist */
  | 'Deleted'

export type RestaurantUser = {
  __typename?: 'RestaurantUser'
  archived: Scalars['Boolean']['output']
  jobs: Array<Scalars['ID']['output']>
  locationGuid: Scalars['ID']['output']
  restaurantUserGuid: Scalars['ID']['output']
}

export type Salary = {
  __typename?: 'Salary'
  activeSalaryRateOfPay?: Maybe<Money>
  employmentTaxType?: Maybe<EmploymentTaxType>
  id: Scalars['ID']['output']
  payFrequency?: Maybe<PayFrequency>
  pendingPayChange?: Maybe<PendingPayChange>
  salaryAllocations: Array<SalaryAllocation>
}

export type SalaryAllocation = {
  __typename?: 'SalaryAllocation'
  id: Scalars['ID']['output']
  jobAssignment: JobAssignment
  rate: Money
}

export type SalaryAllocationNotFoundError = {
  __typename?: 'SalaryAllocationNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type SalaryNotFoundError = {
  __typename?: 'SalaryNotFoundError'
  message?: Maybe<Scalars['String']['output']>
}

export type SalaryPayFrequencyDoesNotExistError = {
  __typename?: 'SalaryPayFrequencyDoesNotExistError'
  message?: Maybe<Scalars['String']['output']>
}

export type SalaryPayFrequencyNoneError = {
  __typename?: 'SalaryPayFrequencyNoneError'
  message?: Maybe<Scalars['String']['output']>
}

export type SalaryRetroPay = {
  __typename?: 'SalaryRetroPay'
  payChangeDifference: AmountWithPayInterval
  payFrequency: PayFrequency
  payPeriodEarnings: Money
  retroPay: Money
}

export type SalaryRetroPayInput = {
  employeeId: Scalars['ID']['input']
  endDate: Scalars['Date']['input']
  newRate: MoneyInput
  oldRate: MoneyInput
  startDate: Scalars['Date']['input']
}

export type SalaryRetroPayResponse =
  | InvalidStartDateError
  | SalaryPayFrequencyDoesNotExistError
  | SalaryPayFrequencyNoneError
  | SalaryRetroPay
  | TimesheetRetrievalError

export type SaveNewEmployeeResponse = {
  __typename?: 'SaveNewEmployeeResponse'
  employeeId: Scalars['ID']['output']
}

export type SeasonalEmploymentStatus = EmploymentStatus & {
  __typename?: 'SeasonalEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
}

export type Section = {
  __typename?: 'Section'
  items: Array<Navigation>
  /** @deprecated Field no longer supported */
  label: Scalars['String']['output']
  labelNew: LocalizableMessage
  name: Scalars['String']['output']
}

export type SectionResult = {
  __typename?: 'SectionResult'
  errors: Array<NavigationErrors>
  result: Array<Section>
}

export type SectionsInput = {
  name?: InputMaybe<Scalars['String']['input']>
  params?: InputMaybe<Array<NavigationParam>>
}

export type SecurityRole =
  | 'APPLICANT'
  | 'CUSTOM'
  | 'CUSTOMER'
  | 'EMPLOYEE'
  | 'FIRED'
  | 'HR_EXECUTIVE'
  | 'HR_PLUS'
  | 'MANAGER'
  | 'NEW_HIRE'
  | 'PAYROLL'
  | 'PAYROLL_OPERATIONS'
  | 'SUPERUSER'
  | 'SUPER_ADMIN'
  | 'SUPER_ADMIN_LIGHT'

export type SendEmailVerificationError =
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch

export type SendVerificationEmailInput = {
  idempotencyKey: Scalars['String']['input']
  source?: InputMaybe<EmailVerificationSourceType>
}

export type SendVerificationEmailResult =
  | DuplicateRequest
  | EmailAlreadyVerified
  | EmailHasBeenUpdated
  | EmailVerificationFailed
  | KeyDoesNotMatch
  | VerificationEmailSent

export type SortDirection = 'ASC' | 'DESC'

export type SplitDepositType = 'DOLLAR' | 'PERCENTAGE'

export type StateInUnitedStates = {
  __typename?: 'StateInUnitedStates'
  abbreviation: Scalars['String']['output']
  fipsCode: Scalars['Int']['output']
  fullName: Scalars['String']['output']
}

export type Supervisor = {
  __typename?: 'Supervisor'
  id: EmploymentId
  job?: Maybe<Scalars['String']['output']>
  location?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
}

export type Supervisors = {
  __typename?: 'Supervisors'
  supervisors: Array<Supervisor>
}

export type TaskStatus =
  | 'CANCELED'
  | 'COMPLETED'
  | 'IN_PROGRESS'
  | 'LOCKED'
  | 'NOT_STARTED'
  | 'ON_HOLD'
  | 'SKIPPED'

export type TerminatedEmploymentStatus = EmploymentStatus & {
  __typename?: 'TerminatedEmploymentStatus'
  adjustedHireDate?: Maybe<Scalars['String']['output']>
  hireDate?: Maybe<Scalars['String']['output']>
  lastDayOfEmployment?: Maybe<Scalars['String']['output']>
  lastDayToReceiveBenefits?: Maybe<Scalars['String']['output']>
}

export type ThirdPartyBenefit = {
  __typename?: 'ThirdPartyBenefit'
  benefitType: ThirdPartyBenefitType
}

export type ThirdPartyBenefitType = 'DENTAL' | 'LIFE' | 'MEDICAL' | 'VISION'

export type TimesheetRetrievalError = {
  __typename?: 'TimesheetRetrievalError'
  message?: Maybe<Scalars['String']['output']>
}

export type ToastVersionIgnored = {
  __typename?: 'ToastVersionIgnored'
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UnlinkUserResult = PayrollUser | UserNotFound | UserNotLinked

export type UpdateEmployeePaymentMethodEmailResponse =
  | EmailInvalidError
  | EmailSendingError
  | EmployeeNotFoundError
  | EmployeePaymentMethodEmailResponse

export type UpdatePayCardAdminSettingsInput = {
  payCardAdminPayoutsLocked?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdatePayCardCustomerSettingsInput = {
  payCardAdminSettings?: InputMaybe<UpdatePayCardAdminSettingsInput>
  payCardPayoutConfiguration?: InputMaybe<PayCardPayoutConfiguration>
  payCardPayoutEnabled?: InputMaybe<Scalars['Boolean']['input']>
  payCardTipsConfiguration?: InputMaybe<PayCardTipsConfiguration>
}

export type UpdatePayCardCustomerSettingsResponse = {
  __typename?: 'UpdatePayCardCustomerSettingsResponse'
  payCardCustomerSettings: PayCardCustomerSettings
}

export type UpdatePendingPayChangeStatusResponse =
  | EmployeeNotFoundError
  | PendingPayChangeNotFoundErrors
  | PendingPayChangeStatusResponse

export type User = {
  __typename?: 'User'
  email?: Maybe<Scalars['String']['output']>
  employeeUuid?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  isEmailVerified: Scalars['Boolean']['output']
  name: PersonName
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  username: Scalars['String']['output']
}

export type UserAlreadyLinked = {
  __typename?: 'UserAlreadyLinked'
  username: Scalars['String']['output']
}

export type UserDocument = {
  __typename?: 'UserDocument'
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type UserDocumentType = 'W2'

export type UserDocuments = {
  __typename?: 'UserDocuments'
  documents: Array<UserDocument>
}

export type UserEvent =
  | UserLinkChanged
  | UserLinked
  | UserRoleAdded
  | UserRoleRemoved
  | UserUnlinked
  | UsernameChanged
  | UsernameChangedWithConflict

export type UserLinkChanged = {
  __typename?: 'UserLinkChanged'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  newToastIdentityGuid: Scalars['ID']['output']
  oldToastIdentityGuid: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserLinked = {
  __typename?: 'UserLinked'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserMigrationChooseAnotherUsername = {
  __typename?: 'UserMigrationChooseAnotherUsername'
  message: Scalars['String']['output']
}

export type UserMigrationConfirmAccountVerificationEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailConfirmationFailed
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationDefaultEmailSource = 'NEW' | 'PAYROLL' | 'TOAST'

export type UserMigrationDefaults = {
  __typename?: 'UserMigrationDefaults'
  canChangeEmail: Scalars['Boolean']['output']
  email?: Maybe<Scalars['String']['output']>
  source: UserMigrationDefaultEmailSource
}

export type UserMigrationDefaultsResult =
  | UserMigrationDefaults
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationEmailConfirmationFailed = {
  __typename?: 'UserMigrationEmailConfirmationFailed'
  error: ConfirmVerificationEmailError
}

export type UserMigrationEmailExistsInCustomer = {
  __typename?: 'UserMigrationEmailExistsInCustomer'
  customerId: Scalars['ID']['output']
}

export type UserMigrationEmailNotVerified = {
  __typename?: 'UserMigrationEmailNotVerified'
  toastAccountType: UserMigrationToastAccountType
  toastIdentityGuid: Scalars['ID']['output']
}

export type UserMigrationEmailVerified = {
  __typename?: 'UserMigrationEmailVerified'
  toastAccountType: UserMigrationToastAccountType
  toastIdentityGuid: Scalars['ID']['output']
  verifiedTime: Scalars['DateTime']['output']
}

export type UserMigrationFailed = {
  __typename?: 'UserMigrationFailed'
  message: Scalars['String']['output']
}

export type UserMigrationMigrateResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailNotVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSuccess
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserMigrationMutation = {
  __typename?: 'UserMigrationMutation'
  confirmAccountVerificationEmail: UserMigrationConfirmAccountVerificationEmailResult
  migrate: UserMigrationMigrateResult
  sendAccountVerificationEmail: UserMigrationSendAccountVerificationEmailResult
}

export type UserMigrationMutationConfirmAccountVerificationEmailArgs = {
  confirmationKey: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type UserMigrationMutationMigrateArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type UserMigrationMutationSendAccountVerificationEmailArgs = {
  email: Scalars['String']['input']
  idempotencyKey: Scalars['String']['input']
  source?: InputMaybe<EmailVerificationSourceType>
}

export type UserMigrationNotEnabled = {
  __typename?: 'UserMigrationNotEnabled'
  message: Scalars['String']['output']
}

export type UserMigrationQuery = {
  __typename?: 'UserMigrationQuery'
  defaults: UserMigrationDefaultsResult
  settings: UserMigrationSettingsResult
  verifyAccountEmail: UserMigrationVerifyAccountEmailResult
}

export type UserMigrationQueryVerifyAccountEmailArgs = {
  email: Scalars['String']['input']
}

export type UserMigrationSendAccountVerificationEmailFailed = {
  __typename?: 'UserMigrationSendAccountVerificationEmailFailed'
  error?: Maybe<SendEmailVerificationError>
}

export type UserMigrationSendAccountVerificationEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSendAccountVerificationEmailFailed
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound
  | UserMigrationVerificationEmailSent

export type UserMigrationSettings = {
  __typename?: 'UserMigrationSettings'
  canDefer: Scalars['Boolean']['output']
}

export type UserMigrationSettingsResult =
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationSettings

export type UserMigrationSuccess = {
  __typename?: 'UserMigrationSuccess'
  companyCodes: Array<Scalars['String']['output']>
  email: Scalars['String']['output']
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
}

export type UserMigrationToastAccountType = 'CREATED' | 'EXISTING' | 'MAPPED'

export type UserMigrationToastUserHasCredentials = {
  __typename?: 'UserMigrationToastUserHasCredentials'
  toastIdentityGuid: Scalars['ID']['output']
}

export type UserMigrationUserAlreadyMigrated = {
  __typename?: 'UserMigrationUserAlreadyMigrated'
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
}

export type UserMigrationUserNotFound = {
  __typename?: 'UserMigrationUserNotFound'
  userId: Scalars['ID']['output']
}

export type UserMigrationVerificationEmailSent = {
  __typename?: 'UserMigrationVerificationEmailSent'
  email: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  expirationTime: Scalars['DateTime']['output']
  idempotencyKey: Scalars['ID']['output']
}

export type UserMigrationVerifyAccountEmailResult =
  | UserMigrationChooseAnotherUsername
  | UserMigrationEmailExistsInCustomer
  | UserMigrationEmailNotVerified
  | UserMigrationEmailVerified
  | UserMigrationFailed
  | UserMigrationNotEnabled
  | UserMigrationToastUserHasCredentials
  | UserMigrationUserAlreadyMigrated
  | UserMigrationUserNotFound

export type UserNotFound = {
  __typename?: 'UserNotFound'
  userId: Scalars['ID']['output']
}

export type UserNotLinked = {
  __typename?: 'UserNotLinked'
  userId: Scalars['ID']['output']
}

export type UserNotMapped = {
  __typename?: 'UserNotMapped'
  toastIdentityGuid: Scalars['ID']['output']
}

export type UserRoleAdded = {
  __typename?: 'UserRoleAdded'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  role?: Maybe<SecurityRole>
  timestamp: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserRoleRemoved = {
  __typename?: 'UserRoleRemoved'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  role?: Maybe<SecurityRole>
  timestamp: Scalars['DateTime']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UserUnlinked = {
  __typename?: 'UserUnlinked'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  timestamp: Scalars['DateTime']['output']
  toastIdentityGuid: Scalars['ID']['output']
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UsernameChanged = {
  __typename?: 'UsernameChanged'
  actorId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  fromUsername?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
  toUsername?: Maybe<Scalars['String']['output']>
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UsernameChangedWithConflict = {
  __typename?: 'UsernameChangedWithConflict'
  actorId: Scalars['ID']['output']
  conflictingUserId: Scalars['ID']['output']
  customerId: Scalars['ID']['output']
  fromUsername?: Maybe<Scalars['String']['output']>
  timestamp: Scalars['DateTime']['output']
  toUsername?: Maybe<Scalars['String']['output']>
  toastIdentityGuid?: Maybe<Scalars['ID']['output']>
  userId: Scalars['ID']['output']
  version: Scalars['Long']['output']
}

export type UsernameExists = {
  __typename?: 'UsernameExists'
  username: Scalars['String']['output']
}

export type Users = {
  __typename?: 'Users'
  list: Array<Maybe<User>>
}

export type VerificationEmailConfirmed = {
  __typename?: 'VerificationEmailConfirmed'
  email: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
}

export type VerificationEmailNotSent = {
  __typename?: 'VerificationEmailNotSent'
  message?: Maybe<Scalars['String']['output']>
}

export type VerificationEmailSent = {
  __typename?: 'VerificationEmailSent'
  email: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  expirationTime: Scalars['DateTime']['output']
  idempotencyKey: Scalars['ID']['output']
}

export type VeteranStatus =
  | 'ACTIVE_WAR_TIME_OR_CAMPAIGN_BADGE_VETERAN'
  | 'ARMED_FORCES_SERVICE_MEDAL_VETERAN'
  | 'DISABLED_VETERAN'
  | 'NOT_A_VETERAN'
  | 'RECENTLY_SEPARATED_VETERAN'
  | 'SPECIAL_DISABLED_VETERAN'
  | 'VETERAN'
  | 'VIETNAM_ERA_VETERAN'

export type ApplicationFeaturesQueryQueryVariables = Exact<{
  [key: string]: never
}>

export type ApplicationFeaturesQueryQuery = {
  __typename: 'Query'
  customer: {
    __typename: 'Customer'
    uuid: string
    enabledFeatures: Array<string>
  }
}

export type BenefitsQueryQueryVariables = Exact<{ [key: string]: never }>

export type BenefitsQueryQuery = {
  __typename: 'Query'
  customer: {
    __typename: 'Customer'
    id: string
    uuid: string
    feins: Array<{
      __typename: 'PayrollFein'
      fein?: string | null
      id: string
      name: string
      benefits: Array<{
        __typename: 'ThirdPartyBenefit'
        benefitType: ThirdPartyBenefitType
      }>
    }>
  }
}

export type BenefitsQueryFeinFragment = {
  __typename: 'PayrollFein'
  fein?: string | null
  id: string
  name: string
  benefits: Array<{
    __typename: 'ThirdPartyBenefit'
    benefitType: ThirdPartyBenefitType
  }>
}

export type DataSharingConsentMutationMutationVariables = Exact<{
  version: Scalars['Float']['input']
}>

export type DataSharingConsentMutationMutation = {
  __typename: 'Mutation'
  updateBenefitsConsent: {
    __typename: 'BenefitsThirdPartyDataSharingConsent'
    version: number
  }
}

export const BenefitsQueryFeinFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BenefitsQueryFein' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollFein' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'benefits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'benefitType' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fein' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BenefitsQueryFeinFragment, unknown>
export const ApplicationFeaturesQueryDocument = {
  __meta__: { hash: 'b1db6f123e914173d404ec4be9978ac7b65d5f95' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ApplicationFeaturesQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enabledFeatures' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  ApplicationFeaturesQueryQuery,
  ApplicationFeaturesQueryQueryVariables
>
export const BenefitsQueryDocument = {
  __meta__: { hash: '80b02e58a73eb38a69c16acc9108395a685be6e3' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BenefitsQuery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'feins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' }
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'BenefitsQueryFein' }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BenefitsQueryFein' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PayrollFein' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'benefits' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'benefitType' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fein' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BenefitsQueryQuery, BenefitsQueryQueryVariables>
export const DataSharingConsentMutationDocument = {
  __meta__: { hash: '4ceff1ed82f29a0f2a858a38892288a4f2392f0e' },
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DataSharingConsentMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'version' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateBenefitsConsent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'version' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'version' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DataSharingConsentMutationMutation,
  DataSharingConsentMutationMutationVariables
>
