import { UserMigrationState } from './state'
import * as React from 'react'
import { UserMigrationAction } from './actions'

export const userMigrationReducer = (
  state: UserMigrationState,
  action: UserMigrationAction
): UserMigrationState => {
  switch (action.type) {
    case 'set-return-url':
      return {
        ...state,
        returnUrl: action.returnUrl
      }
    case 'restart':
      return {
        type: 'initial-state',
        source: 'NEW',
        returnUrl: state.returnUrl
      }
    case 'migration-defaults':
      if (action.source === 'NEW' || !action.email) {
        return {
          type: 'new-email',
          source: 'NEW',
          returnUrl: state.returnUrl,
          showChooseNewEmail: false,
          canChangeEmail: true
        }
      } else if (action.source === 'PAYROLL') {
        return {
          type: 'payroll-email',
          source: 'PAYROLL',
          email: action.email,
          returnUrl: state.returnUrl,
          canChangeEmail: action.canChangeEmail
        }
      } else if (action.source === 'TOAST') {
        return {
          type: 'toast-email',
          source: 'TOAST',
          email: action.email,
          returnUrl: state.returnUrl,
          canChangeEmail: action.canChangeEmail
        }
      }
      break
    case 'select-email':
      return {
        type: 'email-selected',
        email: action.email,
        source: action.source,
        returnUrl: state.returnUrl
      }
    case 'account-verification-email-sent':
      return {
        type: 'wait-for-email-verification',
        email: action.email,
        source: action.source,
        idempotencyKey: action.idempotencyKey,
        returnUrl: state.returnUrl
      }
    case 'email-verified':
      return {
        type: 'email-verified',
        email: action.email,
        source: state.source,
        returnUrl: state.returnUrl
      }
    case 'email-confirmed':
      return {
        type: 'email-confirmed',
        email: action.email,
        source: state.source,
        returnUrl: state.returnUrl
      }
    case 'email-confirmation-error':
      return {
        type: 'email-confirmation-error',
        email: action.email,
        source: state.source,
        returnUrl: state.returnUrl
      }
    case 'wait-for-password':
      return {
        type: 'wait-for-password',
        email: action.email,
        source: state.source,
        returnUrl: state.returnUrl
      }
    case 'migrated':
      return {
        type: 'migrated',
        email: action.email,
        source: state.source,
        toastIdentityGuid: action.toastIdentityGuid,
        userId: action.userId,
        companyCodes: action.companyCodes,
        returnUrl: state.returnUrl
      }
    case 'choose-another-email':
      return {
        type: 'new-email',
        email: action.email,
        source: 'NEW',
        returnUrl: state.returnUrl,
        showChooseNewEmail: true,
        canChangeEmail: true
      }
    default:
      throw new Error(
        `Invalid action ${JSON.stringify(action)} for state ${state.type}`
      )
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

export type UserMigrationDispatch = React.Dispatch<UserMigrationAction>
