export type RtsRequestBody = {
  year: number
  quarter: number
  method: string
  feinList: string[] | undefined
  includeMissingSsns: boolean
}

export type CustomerFein = {
  companyCode: string
  feinUuid: string
  legalName: string
  tin: string
  startDate: Date
}

export type RtsFile = {
  jobId: string
  status: Status
  quarter: number
  year: number
  runType: RunType
  username: string
  startedAt: string
  completedAt: string
  s3FileName: string
}

export enum Status {
  PENDING = 'Pending',
  COMPLETE = 'Complete',
  FAILED = 'Failed'
}

export enum RunType {
  SELECTION = 'Selection',
  ENTERPRISE = 'Enterprise'
}
